@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&display=swap');

.container {
  width: 1000px;
  margin: auto;
  padding-top: 5em;
  font-family: 'Fira Sans', sans-serif;
}

.box-title {
  color: #71bf43;
  font-size: 25pt;
  font-weight: bold;
  margin-top: 3em;
  padding-right: 4em;
}

.box-expiration {
  color: #3F6145;
  font-size: 15pt;
  font-weight: bold;
  margin-top: 3em;
  padding-right: 4em;
  padding-bottom: 3em;
}

.box-expiration span {
  font-size: 20pt;
}

.box-qr {
  background: #3F6145;
  color: #fff;
  padding: 3em;
  text-align: center;
  font-size: 10pt;
  border-radius: 10px;
}

.box-qr span, .box-infos span {
  font-weight: bold;
  font-size: 15pt;
}

.box-qr .qrcode {
  background: #fff;
  padding: 10px;
  width: 220px;
  height: 220px;
  border-radius: 10px;
  margin: auto;
}

.box-infos {
  background: #fff;
  color: #3F6145;
  padding: 3em;
  text-align: center;
  font-size: 10pt;
  border-radius: 10px;
  border: 1px #3F6145 solid;
  margin-top: 2em;
  margin-bottom: 3em;
}

.red-text-1 {
  color: #eb3446;
}

.red-text-2 {
  color: #5c1119;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 4em 1em;
    width: auto;
    text-align: center;
  }

  .box-title {
    margin-top: 2em;
    padding-right: 0;
  }

  .box-expiration {
    margin-top: 2em;
    padding-right: 0;
    padding-bottom: 2em;
  }

  .box-qr, .box-infos {
    padding: 2em;
  }
}