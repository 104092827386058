.mask-phone {
  border: 1px solid #d9d9d9;
  min-height: 32px;
  width: 100%;
  padding-left: 12px;
}

.mask-phone:focus {
  border-color: #40a9ff;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  outline: 0;
}

.mask-phone:hover {
  border-color: #40a9ff;
}