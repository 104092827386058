::-webkit-scrollbar {
   width: 10px;
   height: 10px;
}

::-webkit-scrollbar-track {
  background: #f0f2f5;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: #5a5a5a52;
  border-radius: 30px;
  border: solid 2px rgba(0, 0, 0, 0.05);  
}

.logo {
  color: #fff;
  top: 0;
  text-align: center;
  padding: 15px;
}

.logo a:hover span,
.logo a:hover h4 {
  color: #1890ff !important;
}

.site-layout .site-layout-background {
  
  background: #fff;
}
.toolbar-header{
  display: grid !important;
  grid-template-columns: 1fr minmax(min-content, max-content);
  max-width: 100vw ;
  min-width: 0;
  /* overflow: hidden; */
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;

}

.ant-menu-vertical {
  border: 0 !important;
}

#userMenu .ant-menu-item {
  padding: 0 !important;
}

.spacingBetween {
  float: right;
}

.avatar-icon {
  float: right;
}

.content-layout {
  height: 95%;
}

.containerAmbiente {
  display: flex;
  flex-direction: column;
  align-items: "center";
  line-height: 15px;
}

.butonRenderMenu {
  margin-top: 10px;
  margin-bottom: 10px;
}

.breadcrumbClass {
  margin: 6px 0;
}

.footer-layout {
  text-align: center;
  padding: 2rem;
}

@media only screen and (max-width: 700px) {
  .breadcrumbClass {
    font-size: 11px;
  }

  .menu-sidebar {
    display: none;
  }
}

.companySelector .ant-select-selector {
  padding: 0 !important;
}

.notification-title {
  font-size: 1.25rem;
}

.ant-popover-inner-content:has( > .notification-popover) {
  padding-right: 0 !important;
}

.ant-modal-content {
  border-radius: 8px !important;
}
.ant-modal-header {
  border-radius: 8px 8px 0 0 !important;
}