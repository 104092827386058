.Container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 10px;
}

.QrCode {
  background-color: black;
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;
  min-width: 100% !important;
  min-height: 100% !important;
  width: auto !important;
  height: auto !important;
  z-index: -100 !important;
  background-size: contain !important;
  transform: scaleX(-1) !important;
}

#video {
  position: fixed !important;
  right: 0 !important;
  bottom: 0 !important;
  min-width: 100% !important;
  min-height: 100% !important;
  width: auto !important;
  height: auto !important;
  z-index: -100 !important;
  background-size: contain !important;
  transform: scaleX(-1) !important;
}

.ScanMarker {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: rgba(184, 184, 184, 0.361);
  border: 0px;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
}