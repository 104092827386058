.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5% 3.5%;
}

.icon-one-margin {
  margin-left: 50px;
}

.icon-two-margin {
  margin-left: 20px;
}

.loading-centar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-two-margin-modal {
  margin-left: 20px;
}

.header .headersIcons {
  display: flex;
  align-items: center;
}

div.content {
  margin: 1% 0 0 0;
  position: relative;
}

div.content > img {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

div.content > .content-div {
  position: absolute;
  left: 5%;
  top: 75%;
  background-color: white;
  width: 90%;
  height: 70%;
  border-top: 8px solid #71bf43;
}

.title {
  color: #017f3e;
  font-size: 45px;
  padding-left: 30px;
  text-align: left;
  margin-top: 40px;
}

.sub-title {
  font-size: 18px;
  line-height: normal;
  padding-left: 30px;
  text-align: left;
  align-items: top;
  width: 70%;
}

.titleApp {
  color: #017f3e;
  font-size: 40px;
  padding-left: 30px;
  margin-top: 60px;
  text-align: left;
  align-items: top;
}

.subTitleApp {
  font-size: 20px;
  line-height: normal;
  padding-left: 30px;
  text-align: left;
  width: 70%;
}

.subTitleApp2 {
  font-size: 17px;
  padding-left: 30px;
  margin-top: 1px;
}

.linksApp {
  margin-top: 1rem;
  padding-left: 30px;
  margin-bottom: 40px;
}

img.android {
  height: 3rem;
  padding-right: 10px;
}

img.ios {
  height: 3rem;
}

.cell-phone {
  grid-area: phone-image;
  padding-right: 20px;
  margin-bottom: 40px;
}

img.phone {
  display: flex;
  object-fit: contain;
  width: 80%;
}

.screen {
  display: grid;
  grid-template-areas: 'message phone-image'
                       'store-link phone-image';
  max-width: 100%;
  margin-bottom: 60px;
}

.message-grid {
  grid-area: message;
}

.store-link {
  grid-area: store-link;
}

.icon-color {
  color: #017f3e;
}

@media only screen and (max-width: 950px){

  .icon-color {
    margin-left: 15px;
  }

  .button-download {
    margin-left: 15px;
  }

  .footerBottom {
    margin-left: 15px;
    margin-bottom: 5px;
    padding-bottom: 80px;    
  }

  .header .headersIcons {
    display: none;
  }
  
  .screen {
    grid-template-areas: 'message'
                          'store-link '
                          'phone-image';
  }

  .cell-phone {
    place-self: center;
    margin-top: 20px;
  }

  img.phone {
    width: 20rem;
  }

  .linksApp {
    place-self: center;
  }
}

.icon-color:hover {
  color: #009c4b;
}

.icon-color-2 {
  color: #71bf43;
}

.icon-color-2:hover {
  color: #60a339;
}

.icon-size {
  font-size: 20px;
}

.line {
  text-indent: 2.7em;
}

.separator {
  width: 70px;
  border-top: 1px #017f3e solid;
  float: left;
  margin-left: 35px;
  margin-top: 15px;
}

.content-body {
  margin-top: 4%;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  align-items: baseline;
  border: 1px solid #ebebeb;
  border-radius: 3px;
}

.timeline {
  margin-left: 3%;
}

.timeline-item {
  color: #757575;
}

.text-loading {
  text-align: center;
  padding-bottom: 4%;
  color: #757575;
}

.form {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ebebeb;
  margin-left: 5%;

  width: 100%;
  height: 100%;
  padding: 30px;
}

.privacy-policy {
  color: #017f3e;
}

.ant-radio-button-wrapper-checked {
  background-color: #71bf43 !important;
  border-color: #71bf43 !important;
}

.ant-radio-button-wrapper-checked::before {
  background-color: #71bf43 !important;
}

.ant-radio-button-wrapper-checked:hover {
  background-color: #60a339 !important;
  border-color: #60a339 !important;
}

.button-content:hover {
  color: #71bf43;
  border-color: #71bf43;
}

.ant-radio-button-wrapper {
  color: #757575;
}

.button-content {
  font-weight: bold;
  font-size: 9pt;
}

.input-data {
  width: 39%;
}

.button-search-color {
  background-color: #017f3e;
  border-color: #017f3e;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
  margin-top: 10px;
}

.button-search-color:hover {
  background-color: #009c4b;
  border-color: #009c4b;
}

.point-mouse {
  cursor: pointer;
}

.remove-ident {
  margin-left: -3%;
}

.header-contract {
  width: 392px;
  height: 80px;
  left: 531px;
  top: 694px;
  background: #f5f5f5;
  padding: 12px;
}

.header-plots-opened {
  width: 337px;
  height: 80px;
  left: 937px;
  top: 694px;
  background: #ff5858;
  padding: 12px;
  color: #fff;
}

.header-plots-opened-success {
  width: 337px;
  height: 80px;
  left: 937px;
  top: 694px;
  background: #017f3e;
  padding: 12px;
  font-size: larger;
  color: #fff;
}

.mask-input {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 50px;
  width: 303px;
  padding-left: 12px;
}

.mask-input:focus {
  border-color: #40a9ff;
  border-right-width: 1px;
  box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
  outline: 0;
}

.mask-input:hover {
  border-color: #40a9ff;
}

.footerIcons {
  display: none;
}

.footer {
  text-align: left!important;
}

.renderCopyright {
  display: none;
}

.icone-phone-1,
.icone-phone-2 {
  color: #757575;
}

.icone-phone-2 {
  margin-left: 20px;
}

.button-download {
  background: #017f3e;
  color: #fff;
  border-radius: 5px;
  border-color: #fff;
  margin-right: 1%;
}

.button-download:hover {
  background: #03a04f;
  color: #fff;
  border-radius: 5px;
  border-color: #fff;
  margin-right: 1%;
}

.button-send-mail {
  color: #017f3e;
  border-color: #017f3e;
  border-radius: 5px;
}

.button-send-mail:hover {
  color: #03a04f;
  border-color: #03a04f;
}

.title-modal {
  display: flex;
  overflow: hidden;
  /* justify-content: space-around; */
}

.title-modal h2 {
  font-size: large;
  margin-left: 20px;
}

.title-modal h3 {
  font-size: medium;
}

.sub-title-modal {
  text-align: center;
  font-size: x-large;
}

.description-modal {
  text-align: center;
}

.icons-modal {
  display: flex;
  /* justify-content: center; */
}

.footer-modal {
  display: flex;
  justify-content: flex-end;
  margin-top: 3%;
}

.button-modal {
  color: #fff;
  background-color: #017f3e;
  border-radius: 5px;
  /* width: 20%; */
}

.button-modal:hover {
  color: #fff;
  background-color: #03a04f;
  border-radius: 5px;
  /* width: 20%; */
}

.input-send-mail {
  height: 40px;
  border-radius: 5px;
  font-size: medium;
}

.header-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.screen-card-payment-is-open {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.table-row-red {
  color: #ff0000;
}

.table-row-dark {
  color: #000000;
}

.icon-text-color {
  color: #747474;
}

.copyright {
  padding-bottom: 10%;
  color: #757575;
}

.table-results {
  margin-top: 20px;
  margin-bottom: 4rem;
}

@media only screen and (max-width: 600px) {
  .timeline {
    display: none;
  }

  div.content > .content-div {
    position: absolute;
    left: 0;
    top: 75%;
    background-color: white;
    width: 100%;
    height: 70%;
    border-top: 8px solid #71bf43;
  }

  .separator {
    width: 70px;
    border-top: 1px #017f3e solid;
    float: left;
    margin-left: 15px;
    margin-top: 10px;
  }

  .copyright {
    padding: 8%;
    padding-top: 2%;
  }

  .icon-one-margin {
    margin-left: 0px;
  }

  .title-modal h2 {
    font-size: medium;
    margin-left: 20px;
  }

  .title-modal h3 {
    font-size: medium;
  }

  .icon-two-margin {
    margin-left: 0px;
  }

  .icon-two-margin-modal {
    margin-left: 20px;
  }

  .icons-media-social {
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 7%;
    margin-bottom: 5%;
  }

  .icon-size {
    font-size: 30px;
  }

  .title-text {
    display: none;
  }


  .size-return {
    font-size: 14px;
  }

  .line {
    text-indent: 1em;
    text-align: left;
  }

  .sub-title {
    font-size: 18px;
    text-indent: 0em;
    line-height: 17px;
    padding-top: 10px;
  }

  .sub-title-text {
    display: inline-block;
    text-indent: 0.7em;
    line-height: 16pt;
  }

  div.content > img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  .form {
    border: 1px solid #ebebeb;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: 20px 10px;
  }

  .input-data {
    margin: 0;
    width: 100%;
  }

  .text-choice {
    margin: 0;
    width: 84%;
  }

  .button-search-color {
    margin-top: -5px;
  }

  .recaptcha-before {
    height: 7em;
    position: relative;
  }

  .recaptcha {
    margin: 0;
    position: absolute;
    top: 30%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }

  .header-full {
    height: 100%;
  }

  .header-table {
    height: 100%;
    display: block;
    text-align: left;
    margin-left: 2%;
  }

  .return-screen {
    /* position: absolute;
    top: 70%;
    left: 1%; */
    float: left;
    margin-top: 20px;
  }

  .title-not-found {
    text-align: left;
  }

  .content-not-found br {
    display: none;
  }

  .content-not-found {
    padding: 5%;
  }

  .screen-error {
    padding: 5%;
    padding-bottom: 0;
  }

  .screen-error br {
    display: none;
  }

  .title-screen {
    font-size: larger;
    text-align: left;
  }

  .hide-icon {
    display: none;
  }

  .icons-mobile {
    text-align: left;
  }

  .icon-phone-mobile {
    font-size: small;
    padding-bottom: 10px;
  }

  .remove-ident {
    text-align: left;
    margin-left: -7%;
  }

  .mask-input {
    border-radius: 5px;
    height: 50px;
    margin-bottom: 10px;
    width: 300px;
    margin-top: 10px;
  }

  .header .logoImg {
    padding: 3%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }

  .header .headersIcons {
    display: none;
  }

  .footerIcons {
    display: block;
    margin: 0 auto;
    margin-top: 3%;
    line-height: 30px;
  }

  .footerIconsTitle {
    text-align: center;
    line-height: 15px;
  }

  .icone-phone-1 {
    margin: 0 auto;
    width: 90%;
    text-align: center;
  }

  .icone-phone-2 {
    margin: 0 auto;
    width: 80%;
    text-align: center;
  }

  .icons-media {
    display: none;
  }

  .renderCopyright {
    display: block;
    text-align: center;
    margin: 0 auto;
  }

  .screen-card-payment-is-open {
    display: block;
    padding: 1%;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }

  .header-contract {
    width: auto;
    height: 80px;
    left: 0px;
    top: 0px;
    background: #f5f5f5;
    padding: 12px;
    margin-bottom: 3%;
  }

  .contract-not-found {
    padding: 2%;
    text-align: left;
  }

  .contract-not-found-title {
    font-size: medium;
    text-align: left;
  }

  .ul-mobile {
    padding-left: 3%;
  }

  .header-plots-opened {
    width: auto;
    height: 80px;
    left: 0px;
    top: 0px;
    background: #ff5858;
    padding: 12px;
    color: #fff;
  }

  .header-plots-opened-success {
    width: auto;
    height: 80px;
    left: 0px;
    top: 0px;
    background: #017f3e;
    padding: 12px;
    font-size: larger;
  }

  .title-screen-table-mobile {
    font-size: small;
    padding: 1%;
    padding-top: 0;
    font-weight: bold;
  }

  .buttons-mobile {
    padding: 5%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .button-download {
    background: #017f3e;
    color: #fff;
    border-radius: 5px;
    border-color: #fff;
    margin-right: 1%;
    margin-bottom: 3%;
    width: 100%;
  }

  .button-send-mail {
    color: #017f3e;
    border-color: #017f3e;
    width: 100%;
    border-radius: 5px;
  }

  .title {
    font-size: 1.7rem;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  
  .sub-title {
    font-size: 1rem;
    line-height: normal;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
  
   .titleApp {
    color: #017f3e;
    font-size: 1.7rem;
  } 
  
  .subTitleApp {
    font-size: 1rem;
    line-height: normal;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
  }
  
  .subTitleApp2 {
    padding-left: 15px;
    padding-right: 8px;
    padding-top: 0;
    margin-top: 1px;
  }
  
  img.android {
    height: 4rem;
  }
  
  img.ios {
    height: 4rem;
  }  
  
  .cell-phone {
    place-self: center;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  img.phone {
    width: 15rem;
  }

  .screen {
    grid-template-areas: 'message'
                          'store-link '
                          'phone-image';
    padding: 0.5rem;
    
  }
}
