.card-maps {
  margin-top: 30px;
}

.mt-1 {
  margin-top: 10px;
}

.mt-2 {
  margin-top: 20px;
}

.mt-3 {
  margin-top: 30px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.adjust-sometimes {
  padding: 0 !important;
}

.adjust-mobile {
  padding-right: 10px;
}