.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  height: 75px
}
.pageFooter {
  background-color: #fff;
  padding-left: 80px;
  margin-top: 40px;
}
.content {
  width: 100%;
  padding: 2rem 4rem;
  max-width: 600px;
  margin: 0 auto;
  align-self: center;
}

.layoutContainer {
  min-height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  /* margin-bottom: 4rem; */
  color: #27272a
}

.input {
  height: 40px;
  /* width: 100%; */
}

.subtitle {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 2rem;
  color: #27272a
}

.subtitle span {
  color: #000;
  font-weight: 700;
}

.button {
  border-radius: 6px;
}


.borderWrapper {
  padding:   2rem 1rem;
  border-radius: 10px;
  border: 1px solid #ccc;
}

@media (max-width: 768px) {
  .content {
    padding: 2rem 1rem;
    width: 100%;
  }

  .title {
    font-size: 1rem;
    
  }
  .pageHeader {
    justify-content: center;
  }

  .pageFooter {
    padding: 0 1rem 80px 1rem;
  }
  .contactWrapper {
    display: none;
  }
}