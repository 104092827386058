.select-container {
  display: flex;
  gap: 10px;
}

.select-button {
  padding: 10px 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  cursor: pointer;
  color: #333; /* Cor padrão do texto */
}

.select-button.selected {
  background-color: #3e8eff;
  border-color: #40a9ff;
  color: white; /* Texto branco quando selecionado */
}

.select-button:not(.selected):hover {
  background-color: #e6e6e6;
}

.custom-modal-content {
  background-color: #f6fbf2; /* Cor de fundo personalizada */
}