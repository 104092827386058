.header-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-color-2 {
  color: #71bf43;
}

.icon-color-2:hover {
  color: #60a339;
}

.point-mouse {
  cursor: pointer;
}

.size-return {
  font-size: 14px;
}

.header-table {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-class-top {
  margin-top: 1%;
}

@media only screen and (max-width: 600px) {
  .header-table {
    display: block;
    text-align: left;
    margin-left: 2%;
  }
}