.title-ca {
  text-align: center;
  font-size: 26px;
  color: black;
}

.paragrafo {
  text-align: center;
  font-size: 18px;
}
