.cards {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 6%;
}

.card {
  width: 300px;
  height: 300px;
  background-size: cover;
  cursor: pointer;
}

.card-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-image {
  height: 233px;
}

.input {
  border-radius: 3%;
  height: 50px;
  margin-top: 12%;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spacing-top {
  margin-top: 6%;
}

.spacing-top-medium-card {
  margin-top: 2%;
}

.spacing-top-medium-button {
  margin-top: 5%;
}

.flex-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.button-primary {
  border-radius: 5%;
  color: #fff;
  background-color: #017f3e;
  border-color: #017f3e;
}

.button-primary:hover {
  background-color: #10d46f;
  border-color: #10d46f;
}

.button-default {
  border-radius: 5%;
  color: #017f3e;
  border-color: #017f3e;
}

.button-default {
  color: #10d46f;
  border-color: #10d46f;
}

.text {
  font-size: large;
  font-weight: bold;
}

.text-h1 {
  font-size: xx-large;
  font-weight: bold;
}

.img-border {
  border: 1px solid #f1eeee;
}

.img-card {
  margin-left: 3%;
}

.img-text {
  padding-left: 12%;
  font-weight: bold;
}

.img-text-proposal {
  padding-left: 10%;
  font-weight: bold;
}

.img-text-link {
  margin-left: 10px;
}

.size-card {
  max-width: 300px;
  max-height: auto;
}

.img-text-name {
  font-size: 10px;
}

.flex-col {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.cursor {
  cursor: pointer;
}

.result-vendedor {
  padding-left: 3%;
}

.radioStyle {
  display: block;
  height: 30px;
  line-height: 30px;
}

.line-active {
  color: #017f3e;
  cursor: not-allowed;
}

.line-inactive {
  color: #c6ccc9;
  cursor: not-allowed;
}

.reservation-title {
  border: #c7c41a solid 1px;
  height: 150px;
}

.reservation {
  border: #e4e4de solid 1px;
  height: 250px;
}

.pre-information {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding-top: 3%;
  font-size: x-large;
}

.information {
  padding: 3%;
}

.information-title {
  font-size: x-large;
}

.buttons {
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.sql-reservaion-confirm {
  color: #10d46f;
  margin-left: 30px;
}
