.center {
  display: flex;
  justify-content: space-between;
  width: 500px;
}

.check {
  display: flex;
  justify-content: space-between;
}

.radio {
  margin-left: 30px;
}

.displayButtons {
  display: flex;
  justify-content: flex-end;
}
