.setting-service-field-form  .ant-form-item {
  margin-bottom: 1rem;
}

.ant-form-item.horizontal-form-item, .ant-form-item.compact-form-item {
  margin-bottom: 0;
}
.horizontal-form-item > .ant-form-item-row {
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
}
.horizontal-form-item .ant-form-item-row {
  justify-content: space-between;
}


.horizontal-form-item > .ant-form-item-row > .ant-form-item-control {
  max-width: 60px; 
  padding-bottom: .75rem;
}
