.kanban-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  background-color: #f5f7f9;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 0px rgba(191, 191, 191, 1);
  overflow: hidden !important;
  margin-top: 8px;
  max-height: calc(100vh - 250px);
}

.column-header {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.column-header-title {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 600;
}

.column-body {
  overflow-y: auto;
  height: 100%;
  padding: 8px;
}

.column-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.column-body::-webkit-scrollbar {
  width: 6px;
  height: 8px;
  background-color: #f5f5f5;
}

.column-body::-webkit-scrollbar-thumb {
  background-color: #a7a7a7;
}

.fullscreen-enabled .column-body {
  max-height: 90vh;
}

.header-color-tag {
  position: absolute;
  width: 100%;
  height: 4px;
}

.kanban-spacing-mobile {
  margin-bottom: 4px;
}

.empty-box svg {
  max-width: 100%;
}