.kanban-board-container {
  display: flex;
  justify-content: space-around;
  gap: 8px;
  width: 100%;
  min-height: calc(100vh - 315px);
}

.filter {
  display: flex;
  justify-content: space-around;
}

.fullscreen {
  height: 100%;
}

.filter-tasks {
  margin-bottom: 9px;
}

.header-button{
  width: 40px;
  height: 40px;
}

.fullscreen-enabled {
  padding: 10px;
  background-color: #ffffff;
}

.fullscreen-enabled .kanban-board-container {
  min-height: 90vh;
}

.filter-input {
  width: 270px;
  margin-right: 10px;
}

.filter-select {
  width: 200px;
}

@media only screen and (max-width: 600px) {
  .filter-input {
    margin-bottom: 3%;
  }

  .filter-select {
    width: 230px;
  }
}
