.button-download-negociation {
  border-radius: 5px;
  margin-right: 1%;
}

.button-send-mail-negociation {
  border-radius: 5px;
}

.button-modal-negociation {
  border-radius: 5px;
}

.arrow {
  animation: MoveUpDown 1s linear infinite;
  position: absolute;
  left: 0;
  bottom: 800px;
}

.buttons-pix-paylink {
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
}

.row-send {
  display: flex;
  align-items: center;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 0;
  }
  50% {
    bottom: 100px;
  }
}
