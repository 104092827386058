.content-unified-query {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.content-contract-unified-query {
  border: 1px solid #e2dcdc;
  border-radius: 4px;
}

.content-contract-unified-query-margin-left {
  margin-left: 10px;
}

.content-contract-unified-query-margin-top {
  margin-top: 30px;
}

.content-header-contract-unified-query {
  border-bottom: 1px solid #e2dcdc;
  height: 50px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
}

.content-header-contract-right-unified-query {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content-header-contract-text-unified-query {
  color: #c0bcbc;
  font-size: 10px;
  font-weight: bold;
  margin-right: 15px;
}

.content-header-contract-icon-unified-query {
  transform: rotate(45deg);
}

.unified-query-contract-table-row-click {
  cursor: pointer;
}
