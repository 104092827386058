.header {
  display: flex;
  justify-content: space-between;
}

.detailsAttendance {
  display: flex;
  align-items: center;
}

.detailsAttendanceMargin {
  margin-left: 18%;
}

.detailsAttendanceMarginMedium {
  margin-left: 3%;
}

.headerItemTitle {
  font-weight: bolder;
  padding-right: 10px;
}

.buttonGroups {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.text-atencao {
  color: rgb(238, 26, 26);
  text-align: right;
}

.font {
  font-size: smaller;
}

.space-button-details {
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .buttonGroups {
    justify-content: center;
  }
}

.w-100{
  width: 100%;
}

.mb-5{
  margin-bottom: 50px !important;
}

.adjust-sometimes{
  padding: 0 !important;
}

.adjust-mobile{
  padding-right: 10px;
}

.button-attachment-item > span {
  max-width: 179px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}