.container {
  display: flex;
  flex-direction: column;
  padding-top: 0;
  overflow: auto;
  height: calc(100vh - 64px);
}

.content {
  color: #556074
}

.headerTitle {
  font-size: 1.5rem;
  font-weight: 600;
  color: #172b4d;
}

.notificationItem {
  padding: 0 .5rem;
  border-radius: 5px;
}

.notificationItem:hover {
  background-color: #f5f5f5;

}


.title {
  font-size: 1rem;
  font-weight: 600;
  color: #172b4d !important;
  padding-right: .7rem;
}

.title:hover {
  color: #172b4d;
}

.dateTime {
  color: #6b778c;
  font-size: .87rem;
  padding-right: 1rem;
}


.container::-webkit-scrollbar-thumb {
  background: #dad7d7;
  border-radius: 30px;
}

.badgeButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 14px;
  right: 10px;
  appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px #dad7d7;
  padding: 0;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.2s ease-in-out;
}

.badgeButton:hover {
  background-color: #dad7d776;
}

.innerButton {
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 0;
  background-color: hwb(205 6% 9%);
  border-radius: 50%;
}