.header-attendances {
  padding: 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-bottom: 2px;
}

.click {
  cursor: pointer;
}

.footer-attendances {
  margin-top: 2%;
}

.border-radius {
  border-radius: 3px;
}
.rows {
  margin-top: 5px;
  padding: 5px;
  padding-right: 0;
  padding-left: 0;
}

.maring-button {
  margin-top: 10px;
}

.bars {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.bars-modal {
  display: flex;
  align-items: center;
}

.spacing-modal {
  margin-right: 5%;
}

.space {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.space-left {
  margin-left: 5px;
}

.tag {
  border-radius: 10%;
  font-size: 10px;
}

.bottom {
  margin-bottom: 5px;
}

.fonts {
  font-size: 10px;
}

.pointer {
  cursor: pointer;
}

.alertWarning {
  margin-top: 10px;
}

.size {
  width: 95%;
  margin-left: 7%;
}

.size-header-icons {
  margin-left: 12%;
}

.float-right {
  float: right;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button {
  margin-left: 30px;
}

.loading-centar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-loading {
  text-align: center;
  padding-bottom: 4%;
  color: #757575;
}

.border-details-monitor {
  border: 1px solid #e6e4e4;
  padding-left: 10px;
  padding-top: 10px;
}

.border-details-monitor-text {
  padding-left: 10px;
}
