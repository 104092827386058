.form-content {
  -webkit-border-radius: 10px 10px 10px 10px;
  border-radius: 10px 10px 10px 10px;
  background: #fff;
  padding: 30px;
  width: 100%;
  max-width: 500px;
  max-height: 400px;
  position: relative;
  padding: 0px;
  -webkit-box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.login-page-padding-form {
  padding: 30px;
}

.login-page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.login-page-forgot-password-link {
  font-size: 1rem;
  font-weight: 600;
}

.login-page-forgot-password-link:hover {
  background-color: rgba(24, 144, 255, 0.3);
  padding: 8px;
  border-radius: 10px;
}

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 90%;
  padding: 20px;
}
