.gridContainer {
  display: grid;
  grid-template-columns: auto 300px;
  
}

.chatHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem ;
  border: 1px  solid #ccc;
  border-bottom: none;
  border-radius:  5px 0 0;
}

.chatBody {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #ccc;
  overflow-y: auto;
  max-height: 500px;
}

/* Sobrescreve a autura máxima */
.chatBody[scroll="none"] {
  max-height: none;
}

/* Chat message */

/* Estilo para o container da mensagem */
.messageContainer {
  display: flex;
  flex-direction: column;
  margin: 10px;
  max-width: fit-content;
}



/* Estilo para o conteúdo da mensagem */
.messageContent {
  background-color: #f2f2f2;
  border-radius: 8px;
  padding: 10px;
  max-width: 600px;
}

.messageContent[direction="right"] {
  background-color: #dddddd;
}

/* Estilo para o cabeçalho da mensagem */
.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

/* Estilo para o remetente da mensagem */
.messageSender {
  font-weight: bold;
  margin-right: 10px;
}

/* Estilo para o horário da mensagem */
.messageTime {
  color: #555;
}

/* Estilo para o corpo da mensagem */
.messageBody {
  margin-bottom: 10px;
}

/* Estilo para o texto da mensagem */
.messageText {
  word-wrap: break-word;
}

/* Estilo para imagem da mensagem */
.messageImage {
  max-width: 100%;
}


/* Contact details */

.userContainer {
  border: 1px solid #ddd;
  border-left: none;
  padding: 16px;
  border-radius: 0 5px 0 0;
}

.userContainer h2 {
  color: #333;
}

.userContainer p {
  margin: 8px 0;
  color: #666;
}