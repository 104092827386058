.modal-details .ant-descriptions-item-label {
  font-weight: 500;
}

.modal-details .ant-modal-body {
  padding: 24px 8px;
}

.ant-form {
  transition: all 0.3s;
}

.form-item-cpf,
.form-item-codigo {
  width: calc(100% - 90px);
}

.ant-descriptions-item-label {
  min-width: 140px;
}

.payment-description * {
  max-width: 100%;
}

.pix-payments-table span.ant-tag  {
  min-width: 102px !important;
}