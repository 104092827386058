.text-error {
  color: rgb(233, 64, 64);
}

.text-center {
  text-align: center;
}

.display {
  display: flex;
  justify-content: space-evenly;
}

.input-number {
  width: 435px;
}

.col-spacing {
  margin-top: 10px;
}

.text-center {
  text-align: center;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin-right: 3%;
}
