.site-page-header-ghost-wrapper {
  background-color: #f5f5f5;
  border-radius: 2%;
  width: 100%;
  margin-bottom: 20px;
}

.movements {
  padding: 10px;
  border: #f5f5f5 solid 1px;
  width: 100%;
  margin-bottom: 2px;
}

.buttonRemove {
  margin-top: 28px;
}

.icon-check {
  font-size: large;
  color: green;
}
