.task-paper {
  background: #ffffff;
  outline: 0;
  z-index: 100;
  margin-bottom: 8px;
  /* padding: 6px; */
  cursor: pointer;
  border: 1px;
  border-style: solid;
  border-color: #d6d6d6;
  border-radius: 8px;
}

.task-paper:hover {
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.color-font {
  color: #ffffff;
}

/* .task-header {
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-title {
  font-size: 0.875rem;
}

.blocked {
  color: #d72d30;
  padding: 3px 8px;
  display: inline-block;
  line-height: 1.2;
  vertical-align: middle;
}

.task-body {
  padding-bottom: 8px;
}

.task-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.75rem;
  gap: 4px;
}

.footer-setting-service {
  font-size: 0.75rem;
  padding: 3px 8px;
  display: inline-block;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  vertical-align: middle;
}

.is-blocked {
  font-size: 0.75rem;
  padding: 3px 8px;
  display: inline-block;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid #d72d30;
  vertical-align: middle;
  background-color: #d72d30;
}

.is-schedulle {
  font-size: 0.75rem;
  padding: 3px 8px;
  display: inline-block;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid #338afa;
  vertical-align: middle;
  background-color: #338afa;
}

.is-not-blocked {
  font-size: 0.75rem;
  padding: 3px 8px;
  display: inline-block;
  line-height: 1.2;
  border-radius: 4px;
  border: 1px solid #71e327;
  vertical-align: middle;
  background-color: #71e327;
}

.pulse {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fad347;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
} */
/* 
@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
} */
