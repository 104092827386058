.loading-centar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-loading {
  text-align: center;
  padding-bottom: 4%;
  color: #757575;
}

tr.ant-table-row :nth-child(1) {
  white-space: nowrap;
}

tr.ant-table-row {
  word-break: break-word;

}

.main-content {
  display: flex;

}

.select-group {
  min-width: 200px;
  display: inline-block;
}

.select-group.mobile {
  display: flex;
  align-items: center;
  width: 100%;

}

.header-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}

.header-group.mobile {
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
}

.row-spaced {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 8px;
}

.row-spaced p, .row-spaced h3 {
  margin-bottom: 0;
}

.row-spaced > p .row-spaced > h2 {
  margin-bottom: 0;
}



.group-actions {
  display: flex;
  gap: 8px;
}