.detailsContainer {
  display: flex;
  
}

.detailItem {
  display: flex;

  gap: 1.5rem;
  margin-left: 8px;
}

.detailItem span {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 600;
}
.detailItem p {
  border-top:  1px solid #abaaaa;
  text-align: center;
  font-size: .8rem;
}

.flexRow {
  display: flex;
  flex-direction: row;
  
}
