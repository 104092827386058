.screen-card-negociation {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.header-plots-opened-negociation {
  width: 337px;
  height: 80px;
  left: 937px;
  top: 694px;
  background: #1890ff;
  padding: 12px;
  color: #fff;
  border-radius: 5px;
}

.header-contract-negociation {
  width: 392px;
  min-height: 90px;
  left: 531px;
  top: 694px;
  background: #f5f5f5;
  padding: 12px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .screen-card-negociation {
    flex-direction: column;
    gap: 20px;
  }

  .header-plots-opened-negociation, .header-contract-negociation {
    width: 100%;
  }

}
