.menu-widgets {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.menu-widgets-text {
  text-align: center;
}

.total-width {
  width: 30%;
}

.menu-widget-unique {
  margin-right: 2px;
}

.menu-widgets-mobile-card {
  margin-top: 1%;
}
