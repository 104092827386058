.cardContainer {
  max-width: 100%;
  width: 500px;
  height: auto;
  padding:   2rem 1rem;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.cardFlagsWrapper {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  margin-bottom: 2rem;
}

.title {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.cardFlag {
  width: 50px;
  height: 30px;
  padding: 0 0.6rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.cardFlag img {
  width: 100%;
  height: auto;
}

.cardButton {
  border-radius: 6px;
}

.cardInput {
  height: 50px;
  width: 100%;
}

.footerMessage {
  font-size: 0.8rem;
  margin: 3rem 0;
  
}

.recaptchaWrapper {
  position: relative;
  margin: 0 auto;
  
}


@media (max-width: 768px) {
  .cardContainer {
    width: 100%;
  }
  
}