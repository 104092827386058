.menu-widgets {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.menu-widgets-text {
  text-align: center;
}

.menu-widgets-mobile-card {
  margin-top: 1%;
}

.filter-date {
  margin-top: 3%;
}
