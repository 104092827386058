.container {
  display: flex;
  height: 100%;
}

.view_content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;
  height: auto;
}

.attachment_item {
  width: 100%; /* Garante que ocupe toda a largura do contêiner pai */
  min-width: 150px; /* Define uma largura mínima para evitar encolhimento */
  max-width: 100%; /* Evita que ultrapasse o contêiner pai */
  padding: 0.5rem 1rem; /* Espaçamento interno */
  border: none;
  outline: none;
  background-color: #fff;
  border: solid 1px #9c9c9c83;
  margin-bottom: 2px; /* Espaçamento entre os itens */
  text-align: left;
  box-sizing: border-box; /* Garante que padding e borda não afetem o tamanho */
}

.attachment_item:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}


.items_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  max-height: min-content;
  max-width: 100%;
  margin-bottom: 6px;
  padding: 4px;
}

.loading_container {
  display: flex;
  justify-content: "center";
  align-items: "center";
  height: "100%";
}

.attachment_img {
  align-self: flex-start;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.aside_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.iframe_spin {
  width: 100%;
  height: 100%;
}

.ant-spin-container:has( > .notification-popover) {
  height: 100%;
}