.chatsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
  border: 1px solid #ebebeb;
  padding: .5rem;
  border-radius: 12px;
}

.chatItem {
  display: flex;
  flex-direction: column;
  
  padding: 12px 10px;
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background-color: #f1f4f5

}

.chatItemDetails {
  display: flex;

  gap: 1.5rem;
  margin-left: 8px;
}

.chatItemDetails span {
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 600;
}
.chatItemDetails p {
  border-top:  1px solid #abaaaa;
  text-align: center;
  font-size: 1rem;
}



.flexRow {
  display: flex;
  flex-direction: row;
  
}

.flexCol {
  display: flex;
  flex-direction: column;
}