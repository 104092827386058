.maps {
  display: flex;
}

.buttons {
  margin-left: 5px;
}

.maps-table {
  opacity: 1;
  transition: 0.5s ease;
  backface-visibility: hidden;
  cursor: pointer;
}

.maps-table:hover {
  opacity: 0.5;
}
