.card-amount {
  color: #fff;
  border-radius: 5px;
}

.text-title {
  color: "white";
  text-align: "center";
  font-size: "18px";
}

.text-value {
  color: "white";
  text-align: "center";
  font-size: "32px";
}