.buttonsClass {
  display: flex;
  justify-content: flex-end;
}

.divSearch {
  padding: 8px;
}

.buttonSearch {
  margin-left: 7px;
}
