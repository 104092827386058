.menu-widgets {
  display: flex;
}

.menu-widgets-text {
  text-align: center;
}

.menu-widgets-mobile-card {
  margin-top: 1%;
}

.menu-widgets-desktop-card {
  margin-left: 1%;
}

.menu-widgets-home {
  display: flex;
}
