.cardButton {
  color: #031838;
  background: #dfeafa;
  border-radius: 2rem;
  cursor: pointer;
  border: none;
  transition: border-radius .3s;
}

.cardButton:hover {
  border-radius: 1rem;
}