.card-amount {
  color: #fff;
  border-radius: 5px;
  padding: 0px;
  margin: 0px;
  border: 0px;
}

.text-title {
  color: "white";
  text-align: "center";
  font-size: "12px";
}

.text-value {
  color: "white";
  text-align: "center";
  font-size: "16px";
}